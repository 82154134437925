function recaptchaCallback() {
    $('#f_recaptcha').valid();
}

$(document).ready(function(){

    // Affiche les bonnes marques quand on clique sur les lettre (brand page)

    $('.exist').click(function() {
        $('.exist').removeClass('active');
        $(this).addClass('active');

        $('.col-singleCard').removeClass('active');

        letterChecked = $(this).text().trim();

        $('.col-singleCard').each(function() {
            dataLetter = $(this).data("letter");
            // console.log(letterChecked);
            // console.log(dataLetter);
            if(letterChecked === dataLetter) {
                $(this).addClass('active');
            }

        })
    })

    $.validator.addMethod("validateRecaptcha", function (value, element) {
          if (grecaptcha.getResponse() == '') {
              return false;
          } else {
              return true;
          }
      }, "Vous devez valider le reCAPTCHA");

    setTimeout(function () {
      if ($('#container_form form').length) {
          $('#contactRgpd').rules('add', {
                validateCgv: true
            });
          $('#f_recaptcha').rules('add', {
              validateRecaptcha: true
          });
        }
    }, 100);

    $.extend($.validator.messages, {
        required: "Ce champ est obligatoire.",
        email: "Veuillez fournir une adresse électronique valide."
    });
    $.validator.addMethod("validateCgv", function (value, element) {
            return $('#contactRgpd').is(':checked');
        }, "Vous devez avoir pris connaissance de notre politique de confidentialité");

    $('#container_form form, #digitalForm').validate({
        errorElement: "span",
        errorPlacement: function (error, element) {
            error.addClass("help-block");
            if (element.attr('type') == 'radio' || element.attr('type') == 'checkbox') {
                var parentElm = element.closest('.form-group');
                error.addClass("checkbox");
                parentElm.append(error);
            } else if (element.attr('type') === undefined) {
                var parentElm = element.closest('.form-group');
                error.addClass("select");
                parentElm.append(error);
            }
            else
                error.insertAfter(element);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-error").removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parents(".form-group").addClass("has-success").removeClass("has-error");
        },
        submitHandler: function (form) {
            form.submit();
        }
    });

    // SMOOTH ANCHOR

    // if we have anchor on the url (calling from other page)
    if(window.location.hash){
        // smooth scroll to the anchor id
        anchor = $(window.location.hash).offset().top - 145;
        $('html,body').animate({
            scrollTop:anchor + 'px'
        },0,'swing');
    }

    function juizScrollTo(element){
        $(element).click(function(){
            var goscroll = false;
            var the_hash = $(this).attr("href");
            var regex = new RegExp("\#(.*)","gi");
            var the_element = '';

            if(the_hash.match("\#(.+)")) {
                the_hash = the_hash.replace(regex,"$1");

                if($("#"+the_hash).length>0) {
                    the_element = "#" + the_hash;
                    goscroll = true;
                }
                else if($("a[name=" + the_hash + "]").length>0) {
                    the_element = "a[name=" + the_hash + "]";
                    goscroll = true;
                }

                if(goscroll) {
                    $('html, body').animate({
                        scrollTop:$(the_element).offset().top - 145
                    }, 'slow');
                    return false;
                }
            }
        });
    };
    juizScrollTo('a[href^="#"]');


    $('.burgerBtn').click(function(){
            $('body').toggleClass('hide-menu');
            $('.burgerBtn').toggleClass('menuOpen');
        });
        $('header.mobile .mainMenu-item').click(function(){
            $(this).toggleClass('activeSub');

            if($(this).hasClass('activeSub')){
                h = $(this).find('.mainMenu-subMenuInner').outerHeight(true);
                $(this).find('.mainMenu-subMenu').css('height', h);
            } else {
                $(this).find('.mainMenu-subMenu').css('height', 0);
            }
    });


    $('.history iframe').each(function() {
        $(this).addClass('video');
        $(this).wrap("<div class='responsive-video'></div>");
    })



  $('.owl-carousel-home').owlCarousel({
    loop: true,
    nav: false,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    smartSpeed: 2000,
    items: 1
  });

  $(".prevBtn").click(function() {
      $('.owl-carousel-home').trigger('prev.owl.carousel');
  });

  $(".nextBtn").click(function() {
      $('.owl-carousel-home').trigger('next.owl.carousel');
  });

  $('.owl-carousel-products').owlCarousel({
    loop: true,
    nav: false,
    items: 1,
    responsiveClass:true,
    dots: false,

    responsive:{
        0:{
          mouseDrag: true,
          touchDrag: true,
          pullDrag: true,
          freeDrag: false,
        },
        991:{
          mouseDrag: false,
          touchDrag: false,
          pullDrag: false,
          freeDrag: false,
          dotsContainer: '#products-customDots',
          animateOut: 'fadeOut',
        }
    }
  });

  $(".prevBtnProd").click(function() {
      $('.owl-carousel-products').trigger('prev.owl.carousel');
  });

  $(".nextBtnProd").click(function() {
      $('.owl-carousel-products').trigger('next.owl.carousel');
  });

  $('.owl-carousel-products-links').owlCarousel({
    loop: false,
    nav: false,
    dots: false,
    items: 1,
    animateOut: 'fadeOut',
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    freeDrag: false
  });


    $('.customDots-Title:first-child').addClass('active');

    $('.customDots-Title').click(function () {

        $('.customDots-Title').removeClass('active');

        $(this).addClass('active');

        $('.owl-carousel-products').trigger('to.owl.carousel', [$(this).index(), 300]);
        $('.owl-carousel-products-links').trigger('to.owl.carousel', [$(this).index(), 300]);
    });


  $('.owl-carousel-brand').owlCarousel({
    loop: true,
    nav: false,
    dots: false,
    responsiveClass:true,
    autoplay: 3000,
    responsive:{
        0:{
            items:2,
            dots: true,
        },
        600:{
            items:3,
        },
        991:{
            items:5,
        }
    }
  });

    $(".prevBtn").click(function() {
        $('.owl-carousel-brand').trigger('prev.owl.carousel');
    });

    $(".nextBtn").click(function() {
        $('.owl-carousel-brand').trigger('next.owl.carousel');
    });

  $('.owl-carousel-activity').owlCarousel({
    loop: true,
    nav: false,
    dots: true,
    items: 1
  });

  $('.owl-logoBrandCarousel').owlCarousel({
    loop: true,
    nav: false,
    dots: false,
    autoplay: 6000,
    responsiveClass:true,
    responsive:{
        0:{
            items:2,
            dots: true,
        },
        600:{
            items:3,
        },
        991:{
            items:5,
        }
    }
  });

    $(".prevBtn").click(function() {
        $('.owl-logoBrandCarousel').trigger('prev.owl.carousel');
    });

    $(".nextBtn").click(function() {
        $('.owl-logoBrandCarousel').trigger('next.owl.carousel');
    });

    $('.owl-brandDestroyed').owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        responsiveClass:true,
        responsive:{
            0:{
                items:2,
                dots: true,
                loop: true,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
                // freeDrag: true,
            },
            600:{
                items:3,
                dots: true,
                loop: true,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
                // freeDrag: true,
            },
            991:{
                items:5,
                loop: false,
                mouseDrag: false,
                touchDrag: false,
                pullDrag: false,
                // freeDrag: false,
            }
        }
  });

    $('.owl-carousel-expert').owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 1,
    });

    $('.owl-blockDiaporama').owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        items: 1,
        navText: ["<img src='/assets/sogedoc/images/diapoArrow.png'>","<img src='/assets/sogedoc/images/diapoArrow.png'>"],
        responsiveClass:true,
        responsive:{
            0:{
                nav: false,
                dots: true,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
            },
            600:{
                dots: true,
                nav: false,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,

            },
            991:{
                nav: true,
            },
        }
    });

    $('.owl-certifications').owlCarousel({
        loop: true,
        nav: true,
        dots: false,
        items: 4,
        navText: ["<img src='/assets/sogedoc/images/diapoArrow.png'>","<img src='/assets/sogedoc/images/diapoArrow.png'>"],
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                nav: false,
                dots: true,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
            },
            600:{
                items:2,
                dots: true,
                nav: false,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
            },
            991:{
                items:3,
            },
            1200:{
                items: 4,
            }
        }
    });

//   $(".prevBtnExp").click(function() {
//       $('.owl-carousel-expert').trigger('prev.owl.carousel');
//   });

//   $(".nextBtnExp").click(function() {
//       $('.owl-carousel-expert').trigger('next.owl.carousel');
//   });

  $('.marker-link').on('click', function () {
      console.log('test');
      google.maps.event.trigger(markers[$(this).data('markerid')], 'click');
  });





    // CTA
    $('.blockCta').wrapInner('<span></span>');






    $('.singleAgence:first-child').addClass('active');

    $('.singleAgence').click(function() {
        $('.singleAgence').removeClass('active');
        $(this).toggleClass('active');

        var height = $(this).find('.agenciesAccordeon').outerHeight(true);
        console.log(height);

        $('.singleAgence').css('height', '45px');

        $(this).css('height', height);

    })

    $('input[type="file"]').change(function(e){
        var fileName = e.target.files[0].name;
        console.log($(this).next());
        $(this).next().text(fileName);
    });

    // Formulaire compte digital toggle form section

    $('.closed').click(function(){
        $(this).toggleClass('active');
        $(this).parent().toggleClass('active');

        if($(this).hasClass('active')){
            h = $(this).parent().find('.innerClosed').outerHeight(true);
            $(this).parent().find('.containerClosed').css('height', h);
        } else {
            $(this).parent().find('.containerClosed').css('height', 0);
        }
    });

    // TOGGLE modifySiret
    $('#digitalForm').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    $('input[name="regdigi[payment]"]').on('change',function() {
        if ($(this).val() == 0) {
            $('#label-paiementcomptant').show();
            $('#label-lcrdirecte').hide();
            $('#RIB').prop('disabled', true);
            $('#ribContainer').hide();
            $('[name="regdigi[group]"]:checked').closest('.radioGroup').hide()
        } else {
            $('#label-lcrdirecte').show();
            $('#label-paiementcomptant').hide();
            $('#RIB').prop('disabled', false);
            $('#ribContainer').show();
            $('[name="regdigi[group]"]:checked').closest('.radioGroup').show()
        }
    });

    $('.toggleSiret').prop('disabled', true)
    $('#siret').on('input', function() {
        $('#siret-error').remove();
        $('#link-error').remove();
        var intRegex = /^\d+$/;

        if (intRegex.test($(this).val()) && $(this).val().length == 14 && $(this).val().length > 0) {

            var siret = $(this).val();
            $.ajax({
                'url': ajaxurl,
                'type': 'GET',
                'async': false,
                'data': '&siret=' + siret + '&action=check_siret_exist',
                success: function (resp) {
                    var exist = JSON.parse(resp);

                    if (exist.exist === false) {
                        $('.toggleSiret').prop('disabled', false);
                        var data = '&siret=' + siret;
                        $.ajax({
                            'url': ajaxurl,
                            'type': 'GET',
                            'async': false,
                            'data': data + '&action=get_company_infos',
                            success: function (response) {
                                var res = JSON.parse(response);
                                $('#ent_social').val(res.raison_social);
                                $('#ent_adress').val(res.adress);
                                $('#ent_adress_more').val(res.adress_more);
                                $('#ent_city').val(res.city);
                                $('#ent_zipcode').val(res.zipcode);
                            }
                        })

                    } else {
                        $('.toggleSiret').prop('disabled', true);
                        $('.toggleSiret').after('<a style="width:30%" href="' + exist.page + '" id="link-error" class="cta blue error help-block"><div class="link"><span>Contactez nous !</span></div></a>');
                        $('.toggleSiret').after('<span id="siret-error" class="error help-block">Ce numéro de siret est deja inscrit dans notre base de données !</span>');
                    }
                }
            });
        } else {
            $('.toggleSiret').prop('disabled', true);
            $('.toggleSiret').after('<span id="siret-error" class="error help-block">Veuillez renseigner un siret valide !</span>')
        }
    });

    $('.toggleSiret').click(function(){
        $('.digitalForm').addClass('siretOrTvaActive');
        $('.containerInfo').addClass('siretOn');
        $('.siretInfo .content').text($('#siret').val());
        $('.notPro').addClass('inactive');
        $('.rightInsert').addClass('active');
    });

    $('.toggleTva').click(function(){
        $('.digitalForm').addClass('siretOrTvaActive');
        $('.containerInfo').addClass('tvaOn');
        $('.notPro').addClass('inactive');
        $('.rightInsert').addClass('active');
    });

    $('.containerInfo').find('.modify').click(function(){
        $('.digitalForm').removeClass('siretOrTvaActive');
        $('.containerInfo').removeClass('tvaOn');
        $('.containerInfo').removeClass('siretOn');
        $('.notPro').removeClass('inactive');
        $('.rightInsert').removeClass('active');

    });

    // Sticky right column Compte digital
    stickySiret();
    $(window).resize(stickySiret);
    $('body').scroll(stickySiret);

    //Lisitng actu
    $('.owl-actuListing').owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        items: 3,
        margin: 30,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                dots: true,
            },
            768:{
                items:2,
                dots: false,
            },
            991:{
                items:3,
                dots: false,
            },
        }
    });

    $(".prevBtn").click(function() {
        $('.owl-actuListing').trigger('prev.owl.carousel');
    });

    $(".nextBtn").click(function() {
        $('.owl-actuListing').trigger('next.owl.carousel');
    });

    $('.owl-actuListingRea').owlCarousel({
        loop: true,
        nav: false,
        dots: false,
        items: 3,
        margin: 30,
        responsiveClass:true,
        responsive:{
            0:{
                items:1,
                dots: true,
            },
            768:{
                items:2,
                dots: false,
            },
            991:{
                items:3,
                dots: false,
            },
        }
    });

    $(".prevBtnRea").click(function() {
        $('.owl-actuListingRea').trigger('prev.owl.carousel');
    });

    $(".nextBtnRea").click(function() {
        $('.owl-actuListingRea').trigger('next.owl.carousel');
    });

});

// Sticky right column single SIT
function stickySiret() {

    if($('.rightInsert').length) {

        // Haut de la page actuelle
        var windowTop = $(window).scrollTop();

        // Bas du menu
        var bottomDiv = $('.rightInsert').offset().top + $('.rightInsert').outerHeight(true);

        // Début du sticky menu
        var topSticky = $('.headerPage').offset().top;

        // Fin du sticky menu
        var bottomSticky = $('.questions').offset().top - 50;

        var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

        if(w > 767) {
            if(bottomDiv < bottomSticky) {
                $('.rightInsert').addClass('sticky');

                if((windowTop + 210) < topSticky) {
                    $('.rightInsert').removeClass('sticky');
                }
            } else {
                $('.rightInsert').addClass('sticky');
            }
        }
    }
}
